import React, { useEffect, useState } from 'react'

import { StorageKeys, Store } from '@/services/storage'
import classNames from 'classnames'
import { Modal } from 'ethos-design-system'

import styles from '@/components/EstimateWidget/FloatingVariation/FloatingForm/experiments/LeadForm/LeadForm.module.scss'
import { LeadFormWrapper } from '@/components/EstimateWidget/FloatingVariation/FloatingForm/experiments/LeadForm/LeadFormWrapper'

interface Props {
  isLeadFormModalOpen: boolean
  setIsLeadFormModalOpen: (value: boolean) => void
}

const CloseIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z"
      fill="#525252"
    />
  </svg>
)

const LeadFormModal = ({
  isLeadFormModalOpen,
  setIsLeadFormModalOpen,
}: Props) => {
  const store = Store.getInstance('localStorage')
  const _wasLeadFormSubmitted = store.getItem<boolean>(
    StorageKeys.LeadFormSubmitted
  )

  const [wasLeadFormSubmitted, setWasLeadFormSubmitted] = useState(
    _wasLeadFormSubmitted
  )

  useEffect(() => {
    // allow user to submit form multiple times
    if (isLeadFormModalOpen && wasLeadFormSubmitted) {
      store.removeItem(StorageKeys.LeadFormSubmitted)
      setWasLeadFormSubmitted(false)
    }
  }, [isLeadFormModalOpen])

  return (
    <Modal
      isOpen={isLeadFormModalOpen}
      onDismiss={() => setIsLeadFormModalOpen(false)}
    >
      <div className={styles.modalContainer}>
        <div
          className={classNames(styles.scrollable, {
            [styles.success]: wasLeadFormSubmitted,
          })}
        >
          <div
            className={classNames(styles.closeIconContainer, {
              [styles.success]: wasLeadFormSubmitted,
            })}
          >
            <div className={styles.modalTitle}>
              {wasLeadFormSubmitted ? '' : 'Talk to an agent'}
            </div>
            <div onClick={() => setIsLeadFormModalOpen(false)}>
              <CloseIcon />
            </div>
          </div>
          <div className="p-4 pt-5 md:p-6 md:pb-4">
            {wasLeadFormSubmitted ? (
              <>All set! An agent will contact you shortly.</>
            ) : (
              <LeadFormWrapper
                onSuccess={() => setWasLeadFormSubmitted(true)}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LeadFormModal
